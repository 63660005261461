import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
 batchesList: [],
 batchesCount: null,
 batches_documentsList: [],
 batchesDetail: {},
 batches_documentsCount: null
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations: mutationsAbstract
}
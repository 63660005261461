
export default [
  {
    path: '/:prefix(test)?/metrics',
    name: 'MetricsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'MetricsList',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_document', 'add_document'],
      use: ['ax'],
      title: 'Métricas'
    },
    component: () => import('@/modules/metrics/MetricsList')
  }
]
import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)
export default new Vuetify({
  icons: {
    iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#0352CC",
        secondary: "#525F7F",
        success: "#88B437",
        error: "#DD1A1A",
        lightBlue: "#B1C9EF",
        defaultGrey: "#525F7F",
        background: "#F4F6FA",
        'grey-200': '#AFB5C4',
        'blue-dark': '#012256',
        // new definition
        lightGrey: "#F4F6FA",
        fontDraw: "#8B94A9",
        'fontDraw-mode': "#8B94A9",
        fontBody: "#3A435A",
        bgSearch: "#FFFFFF",
        bgPanel: "#FFFFFF",
        greyChip: "#63627C",
        placeholder: "#8b94a9",
        off: "#034BBA",
        supportAlert: "#151E34",
        barCompliance: "#FFFFFF",
        blueDarken: "#080C24",
        skeleton: "#EEEFF2",
        yellow: "#E8A803",
        yellowLight: "#FFEFCC",
        redLight: "#F4B8B8",
        greyMedium: "#C5C8D2",
        overlay: "#525F7F",
        secondaryIconDrawer: "#525F7F",
        fontLinkTable: "#3A435A",
        blueGreyLighten: "#C8D2D7",
        fontChip: "#3A435A",
        purpleLight: "#E6E8FC",
        purpleMedium: "#8BAFE8"
      },
      dark: {
        primary: "#116AEF",
        secondary: "#E6E9F0",
        success: "#88B437",
        'blue-dark': '#A9CAFE',
        'fontDraw-mode': "#D9DDE6",
        error: "#E62A2A",
        background: "#080C24",
        lightGrey: "#080C24",
        bgSearch: "#080C24",
        bgPanel: "#080C24",
        defaultGrey: "#E6E9F0",
        greyChip: "#FFFFFF",
        off: "#023A91",
        yellow: "#D19C13",
        barCompliance: "#080C24",
        yellowLight: "#654B09",
        redLight: "#F4B8B8",
        lightBlue: "#143363",
        greyMedium: "#8b94a9",
        blueGreyLighten: "#C8D2D7",
        fontLinkTable: "#E6E8FC",
        secondaryIconDrawer: "#023A91",
        fontChip: "#3A435A",
        purpleLight: "#E6E8FC"
      }
    }
  }
})

import axios from "axios"
import router from "@/router"
import { auth as api } from "@/backend/api"
// import CryptoJS from 'crypto-js'

const state = {
  token: "",
  refresh: "",
  user: {},
  account: {},
  userAccount: {},
  invitation: {},
  trace: {}
}

const actions = {
  SIGN_UP: ({ commit }, { user, invitation }) => {
    return new Promise((resolve, reject) => {
      api
        .signUp(user, invitation)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  GET_AUTH: ({ commit }, { resource, payload }) => {
    return new Promise((resolve, reject) => {
      api
        .getAuth(resource, payload)
        .then((response) => {
          commit("SET_AUTH", response)
          api
            .getUser()
            .then((response) => {
              const user = response.data
              commit("SET_USER", response.data)
              window.analytics.identify(user.email, {
                email: user.email
              })
              // }, {
              //   integrations: {
              //     Intercom: {
              //       user_hash: user.intercom.hash
              //     }
              //   }
              // })
            })
            .catch({})
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  DELETE_AUTH: ({ commit }) => {
    commit("DELETE_AUTH")
  },
  SET_ACCOUNT: ({ commit }, { account, redirect = true }) => {
    commit("SET_ACCOUNT", { account, redirect: redirect })
  },
  TOKEN_VERIFY: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      api
        .tokenVerify(state.refresh)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  REFRESH_TOKEN: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      api
        .getRefreshToken(state.refresh)
        .then((response) => {
          commit("REFRESH_TOKEN", response.data.access)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  ACCEPT_INVITATION: ({ commit }, { key, payload }) => {
    return new Promise((resolve, reject) => {
      api
        .acceptInvitation(key, payload)
        .then((response) => {
          commit("SET_INVITATION", response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  PASSWORD_RESET: ({ commit }, email) => {
    return new Promise((resolve, reject) => {
      api
        .passwordReset(email)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  PASSWORD_RESET_CONFIRM: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .passwordResetConfirm(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  GET_USER: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api
        .getUser()
        .then((response) => {
          resolve(response)
          commit("SET_USER", response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  PATCH_USER: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .patchUser(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  POST_USER_PASSWORD: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .postUserPassword(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  RETRIEVE_TRACE: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api
        .retrieveTrace()
        .then((response) => {
          commit("SET_TRACE", response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {
  SET_AUTH: (state, payload) => {
    state.token = payload.data.access || payload.data.token
    state.refresh = payload.data.refresh
    state.user = JSON.parse(atob(state.token.split(".")[1]))
    state.user.authenticated = true
    router.push("/account/choose")
  },
  DELETE_AUTH: (state) => {
    state.token = ""
    state.refresh = ""
    state.user = {}
    state.account = {}
    delete axios.defaults.headers.common.Authorization
    router.push("/auth/signin")
  },
  SET_ACCOUNT: (state, { account, redirect }) => {
    state.account = account
    if (redirect) {
      router.push({ name: "AccountDashboard" })
    }
  },
  REFRESH_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INVITATION: (state, invitation) => {
    state.invitation = invitation
  },
  SET_TRACE: (state, data) => {
    const trace = {}
    data.split("\n").forEach((prop) => {
      const cleaned = prop.trim().split("=")
      trace[cleaned[0]] = cleaned[1]
    })
    state.trace = trace
  },
  SET_USER: (state, userAccount) => {
    state.userAccount = userAccount
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

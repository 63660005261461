const supplierRoutes = [
  {
    path: '/:prefix(test)?/download-center',
    name: 'DownloadCenterList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DownloadCenterList',
      requireAuth: true,
      requireAccount: true,
      title: 'Centro de descargas'
    },
    component: () => import('@/modules/download-center/views/DownloadCenterList')
  }
]
export default supplierRoutes
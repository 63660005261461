import { actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  suppliersDetail: {},
  suppliersCount: null,
  suppliersList: [],
  retrieveLoader: false,
  createLoader: false,
  deleteLoader: false
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations: mutationsAbstract
}

import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  async_tasks_infoDetail: {},
  async_tasks_infoCount: null,
  async_tasks_infoList: []
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations: mutationsAbstract
}
const purchaseRoutes = [
  {
    path: '/:prefix(test)?/purchases',
    name: 'PurchaseList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      title: 'Compras'
    },
    component: () => import('@/modules/purchase/views/PurchaseList')
  },
  {
    path: '/:prefix(test)?/purchases/:id',
    name: 'PurchaseRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información de documento'
    },
    component: () => import('@/modules/purchase/views/Main')
  }
]
export default purchaseRoutes
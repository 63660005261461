// import store from '@/store'
const summariesRoutes = [
  {
    path: '/:prefix(test)?/summaries/:id',
    name: 'SummariesRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información de documento'
    },
    component: () => import('@/modules/summaries/views/Main')
  },
  {
    path: '/:prefix(test)?/summaries',
    name: 'SummariesList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_document', 'add_document'],
      use: ['fc', 'ax'],
      title: 'Documentos'
    },
    component: () => import('@/modules/summaries/views/SummariesList')
  }
]
const list = () => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return summariesRoutes?.filter(({meta}) => meta?.use?.some((v) => v === env))
}
export default list()
import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  summariesDetail: {},
  summariesCount: null,
  summariesList: [],
  eventsCount: 0,
  eventsList: [],
  eventsDetail: {},
  sequencesDetail: {},
  sequencesCount: null,
  sequencesList: [],
  sendTypeToggleDropdown: ''
}

const mutations = {
  SET_TOOGLE_SENDTYPE: (state, type) => {
    state.sendTypeToggleDropdown = type
  },
  ...mutationsAbstract
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations
}

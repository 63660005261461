import { actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  branchesDetail: {},
  branchesCount: null,
  branchesList: [],
  retrieveLoader: false,
  createLoader: false,
  deleteLoader: false
}

const getters = {
  orgunits: state => {
    return state.orgunitsList
  }
}

export default {
  actions: actionsAbstract,
  getters,
  mutations: mutationsAbstract,
  namespaced: true,
  state
}

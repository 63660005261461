export default [
  {
    path: '/:prefix(test)?/folios',
    name: 'FoliosListCreate',
    props: true,
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_caf', 'add_caf'],
      use: ['fc', 'ax'],
      title: 'Stock de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/FoliosListCreate')
  },
  {
    path: '/:prefix(test)?/folios/stock/:id',
    name: 'FoliosRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Stock de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/FoliosRetrieve')
  },
  {
    path: '/:prefix(test)?/folios/monitor',
    name: 'FoliosMonitor',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      showSupport: true,
      title: 'Monitor de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/FoliosMonitor')
  },
  {
    path: '/:prefix(test)?/folios/monitor/report/braches',
    name: 'FoliosBranchReport',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      showSupport: true,
      title: 'Reporte Tiendas - Monitor de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/FoliosBranchReport')
  },
  {
    path: '/:prefix(test)?/folios/monitor/report/pos',
    name: 'FoliosPosReport',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      showSupport: true,
      title: 'Reporte POS - Monitor de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/PosReport')
  }
]
import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  templatesCount: null,
  templatesList: [],
  templatesDetail: {},
  messagesCount: null,
  messagesList: [],
  messagesDetail: {},
  rulesCount: null,
  rulesList: [],
  rulesDetail: {}
}

const actions = {
  ...actionsAbstract,
  ADD_TEMPORAL_OBJECT ({ commit }, payload) {
    commit('ADD_TEMPORAL_OBJECT', payload)
  },
  DESTROY_TEMPORAL_OBJECT ({ commit }, index) {
    commit('DESTROY_TEMPORAL_OBJECT', index)
  }
}

const mutations = {
  ...mutationsAbstract,
  ADD_TEMPORAL_OBJECT (state, payload) {
    state.rulesList.push(payload)
  },
  DESTROY_TEMPORAL_OBJECT (state, index) {
    state.rulesList.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

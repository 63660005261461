<template>
  <v-sheet class="transparent" width="380">
    <v-menu v-model="menu" offset-y :max-height="300" :nudge-width="100" :nudge-bottom="22" :close-on-content-click="false" transition="slide-y-transition">
      <template v-slot:activator="{menu, attrs}">
        <v-text-field v-model="search" v-bind="attrs" v-on="menu" autocomplete="off" background-color="bgSearch" dense hide-details :outlined="!$vuetify.theme.dark" :solo="$vuetify.theme.dark" placeholder="Buscar..." clearable clear-icon="mdi-close" @click:clear="search = ''">
          <template v-slot:prepend-inner>
            <span class="ml-2 mr-n1">
              <v-icon color="defaultGrey">mdi-magnify</v-icon>
            </span>
          </template>
          <template v-slot:append>
            <v-progress-circular class="mr-2" v-if="loader" indeterminate color="defaultGrey" size="20" width="2" />
          </template>
        </v-text-field>
      </template>
      <template v-if="search.length >= 3">
        <v-col class="pa-0">
          <v-list class="v-list-search">
            <v-list-item class="my-0 mx-2 pa-1">
              <v-list-item-title class="subtitle-2 text--secondary font-weight-medium">Resultados para {{search}}</v-list-item-title>
            </v-list-item>
            <template v-if="!$store.state.accounts.retrieveLoader && searchList.length">
              <template v-for="item in searchList">
                <!-- customer -->
                <v-list-item v-if="item._index === 'customer'" :key="item.id" class="my-0 mx-2 pa-1" @click="goTo('CustomerRetrieveUpdate', item._id)">
                  <v-list-item-title>
                    <v-icon color="defaultGrey" left small>mdi-account-box</v-icon>
                    <span class="subtitle-2 text--secondary font-weight-medium" v-if="item._source.name">{{item._source.name}}</span>
                  </v-list-item-title>
                </v-list-item>
                <!-- end customer -->

                <!-- documents -->
                <v-list-item v-if="item._index === 'documents'" :key="item.id" class="my-0 mx-2 pa-1" @click="goTo('DocumentsRetrieve', item._id)">
                  <v-list-item-title>
                    <v-icon color="defaultGrey" left small>mdi-receipt</v-icon>
                    <span class="subtitle-2 text--secondary font-weight-medium mr-1">{{item._source.total | currency(item._source.currency)}} {{item._source.currency}}</span>
                    <span class="mr-1">{{item._source.document}}</span>
                    <v-chip v-if="item._source.status === 'finalized' && item._source.due_date > today" color="defaultGrey" text-color="secondary" x-small>Pendiente de pago</v-chip>
                    <v-chip v-else-if="item._source.status === 'finalized' && item._source.due_date <= today" color="defaultGrey" text-color="secondary" x-small>Vencido</v-chip>
                    <v-chip v-else-if="item._source.status === 'paid'" color="defaultGrey" text-color="defaultGrey" x-small>Pagado<v-icon class="ml-1" x-small>mdi-check</v-icon></v-chip>
                    <v-chip v-else-if="item._source.status === 'cancelled'" color="defaultGrey" text-color="defaultGrey" x-small>Tiene nota de crédito<v-icon class="ml-1" x-small>mdi-close</v-icon></v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right" v-if="item._source.date">{{item._source.date | date}}</v-list-item-subtitle>
                </v-list-item>
                <!-- end documents -->

              </template>
            </template>
          </v-list>
        </v-col>
        <v-col v-if="!$store.state.accounts.retrieveLoader && !searchList.length" class="text-center py-12">
          <v-icon class="mb-5" color="defaultGrey" size="40">mdi-magnify</v-icon>
          <span class="d-block body-1 text--secondary font-weight-medium">No se han encontrado resultados para tu búsqueda</span>
          <span class="d-block">Inténtalo de nuevo con una búsqueda diferente</span>
        </v-col>
      </template>
    </v-menu>
  </v-sheet>
</template>
<script>
import { mapState } from 'vuex'
import ListViewMixin from "@/mixins/ListViewMixin"
import debounce from 'lodash/debounce'
export default {
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    search: '',
    loader: false,
    menu: false
  }),
  computed: mapState({
    searchList: state => state.accounts.searchList,
    searchCount: state => state.accounts.searchCount
  }),
  watch: {
    search: debounce(function () {
      if (this.search.length < 3) {
        return false
      }
      this.menu = true
      this.getSearch()
    }, 600),
    $route () {
      this.search = ''
    }
  },
  methods: {
    getSearch () {
      const query = {
        query: this.search
      }
      this.loader = true
      this.$store.dispatch('accounts/SEARCH_LIST', {
        resource: 'search',
        query: query
      })
      .finally(() => {
        this.loader = false
      })
    },
    goTo (name, id) {
      this.$router.push({ name: name, params: { id: id, ...this.$route.params }}).catch(err => err)
      this.menu = false
    },
    clearSearch () {
      this.search = ''
    }
  }
}
</script>
const es = {
  api: {
    non_field_errors: 'errores',
    tax_id: 'número fiscal',
    invoice: 'factura {number}',
    lines: 'líneas',
    name: 'nombre',
    discount_scheme: 'tipo de descuento',
    amount: 'monto',
    customer: 'cliente',
    document: 'documento',
    next_number: 'número siguiente',
    date: 'fecha',
    due_date: 'fecha de pago',
    payments: 'pagos',
    file: 'archivo',
    logo: 'logo',
    month: 'Mensual',
    year: 'Anual',
    monthly: 'Mensual',
    yearly: 'Anual',
    daily: 'Diario',
    this_v_last: 'actual vs último',
    this_v_last_mtd: 'actual vs último (MTD)',
    last_v_one_before: 'último vs antepenúltimo',
    tax_agency_failed: 'Emisión fallida',
    reference_expired: 'Referencia expirada'
  },
  metrics: {
    total_mrr: 'Total del MRR',
    expansion_mrr: 'Expansión del MRR',
    new_mrr: 'Nuevo MRR',
    contraction_mrr: 'Contracción del MRR',
    churn_mrr: 'Cancelación del MRR',
    total_billing: 'Emisión total',
    total_tax: 'Impuestos totales',
    net_growth_mrr: 'Crecimiento neto del MRR',
    arpu: 'Ingresos promedio por suscripción',
    churn_count_mrr: 'Cancelación de suscripciones',
    total_billing_non_recurring: 'Emisión total no recurrente',
    net_billing: 'Emisión neta',
    total_cancelled_billing: 'Total de la emisión anulada',
    total_receivables: 'Cuentas por cobrar',
    total_receivables_overdue: 'Cuentas por cobrar: vencidas',
    total_receivables_current_due: 'Cuentas por cobrar: por vencer',
    overdue_billing_recovery_breakdown: 'Antigüedad de cuentas por cobrar',
    '1_to_14': '1 a 14 días',
    '15_to_29': '15 a 29 días',
    '30_to_59': '30 a 59 días',
    more_than_60: 'más de 60 días'
  }
}

export default {
  es
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './sass/main.sass'
import './sass/variables.scss'
import './sass/variables-css.scss'

import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'
import LoadScript from 'vue-plugin-load-script'
import VueClipboard from 'vue-clipboard2'
import VueHighlightJS from 'vue-highlightjs'
import GAuth from 'vue-google-oauth2'
import VuetifyDialog from 'vuetify-dialog'
import VConfirm from './components/VDialog/VConfirm'
import 'vuetify-dialog/dist/vuetify-dialog.css' // remove this
// import pusher from 'vue-pusher'
import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'
import VueGridLayout from 'vue-grid-layout'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import helpers from './utils/helpers'

// Vue.use(pusher, {
//   api_key: process.env.VUE_APP_PUSHER_API_KEY,
//   options: {
//     cluster: process.env.VUE_APP_PUSHER_CLUSTER,
//     encrypted: true
//   }
// })

Vue.config.productionTip = false

Vue.use(VuetifyDraggableTreeview, {
  context: {
    vuetify
  }
})

Vue.use(VueGridLayout, {
  context: {
    vuetify
  }
})

Vue.use(VueTelInput)

// vuetify dialog
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})
Vue.prototype.$dialog.component('warning', VConfirm, {
  waitForResult: true,
  'overlay-color': 'overlay',
  actions: {
    false: 'Cancelar',
    true: {
      text: 'Continuar',
      color: 'primary'
    }
  }
})
Vue.prototype.$dialog.message = {
  info: (message, options) => Vue.prototype.$dialog.toast({
    text: message,
    color: 'blue-grey darken-1',
    position: 'top-center',
    ...options
  }),
  error: (message, options) => Vue.prototype.$dialog.toast({
    text: message,
    color: 'red darken-1',
    position: 'top-center',
    rounded: '10px',
    ...options
  }),
  success: (message, options) => Vue.prototype.$dialog.toast({
    text: message,
    color: 'green darken-1',
    position: 'top-center',
    ...options
  }),
  warning: (message, options) => Vue.prototype.$dialog.toast({
    text: message,
    color: 'amber darken-1',
    position: 'top-center',
    ...options
  })
}
// end vuetify dialog

Vue.use(helpers)
Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(LoadScript)
Vue.use(VueClipboard)
Vue.use(VueHighlightJS)
Vue.use(GAuth, {
  clientId: '338574906830-gddkaner07o2kr73ao9p37ug4c6ubcer.apps.googleusercontent.com',
  prompt: 'select_account',
  fetch_basic_profile: true
})

// Vue.prototype.$JS_BASE = process.env.VUE_APP_JS_BASE
Vue.prototype.$CLIENT_BASE = process.env.VUE_APP_CLIENT_BASE
// Vue.prototype.$API_BASE = process.env.VUE_APP_API_BASE
// Vue.prototype.$PAY_BASE = process.env.VUE_APP_PAY_BASE
// Vue.prototype.$DOCS_BASE = process.env.VUE_APP_DOCS_BASE

const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'en',
  messages: {},
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')

export default [
  {
    title: 'Inicio',
    icon: 'home',
    link: 'AccountDashboard',
    show: true,
    use: ['CL', 'PE'],
    options: []
  },
  {
    title: "Métricas",
    icon: "metrics",
    link: "MetricsList",
    show: true,
    use: ["CL", "PE"],
    options: []
  },
  {
    title: "Emisión",
    icon: "document",
    link: "DocumentsList",
    show: true,
    use: ["CL", "PE"],
    options: [
      {
        title: "Documentos",
        link: "DocumentsList",
        associatedRoutes: ["DocumentsCreate", "DocumentsRetrieve"],
        use: ["CL", "PE"]
      },
      {
        title: "Resúmenes",
        link: "SummariesList",
        associatedRoutes: ["SummariesRetrieve"],
        use: ["CL", "PE"]
      },
      {
        title: "Emisión por lote",
        link: "DocumentsBatchListCreate",
        associatedRoutes: ["DocumentsBatchRetrieve"],
        use: ["CL", "PE"]
      },
      {
        title: "Cuadratura",
        link: "ReconciliationsListCreate",
        associatedRoutes: ["ReconciliationsRetrieve"],
        use: ["CL", "PE"]
      },
      {
        title: 'Clientes',
        link: 'CustomerListCreate',
        associatedRoutes: ['CustomerRetrieveUpdate'],
        use: ['CL', "PE"]
      }
    ]
  },
  {
    title: "Recepción",
    icon: "purchase",
    link: "PurchaseList",
    show: true,
    use: ["PE"],
    options: [],
    associatedRoutes: ["PurchaseRetrieve"]
  },
  {
    title: "Recepción",
    icon: "purchase",
    link: "PurchaseList",
    show: true,
    use: ["CL"],
    options: [
      {
        title: "Documentos",
        link: "PurchaseList",
        associatedRoutes: ["PurchaseRetrieve"],
        use: ["CL"]
      },
      {
        title: "Proveedores",
        link: "SupplierListCreate",
        associatedRoutes: ["SupplierRetrieveUpdate"],
        use: ["CL"]
      }
    ]
  },
  {
    title: "Folios",
    icon: "folios",
    link: "FoliosListCreate",
    show: true,
    use: ["CL"],
    options: [
      {
        title: "Disponibilidad",
        link: "FoliosListCreate",
        associatedRoutes: ["FoliosRetrieve"],
        use: ["CL"]
      }
      // {
      //   title: 'Monitor',
      //   link: 'FoliosMonitor',
      //   use: ['CL'],
      //   showSupport: true
      // }
    ]
  },
  {
    title: "Tiendas",
    icon: "pos",
    link: "BranchListCreate",
    show: true,
    use: ["CL", "PE"],
    options: [
      // {
      //   title: 'Grupos',
      //   link: 'PosGroupsList',
      //     use: ['CL', 'PE']
      // },
      // {
      //   title: 'Tiendas',
      //   link: 'BranchListCreate',
      //   associatedRoutes: ['BranchRetrieveUpdate'],
      //   use: ['CL', 'PE']
      // },
      // {
      //   title: 'Puntos de venta',
      //   link: 'PosListCreate',
      //   associatedRoutes: ['PosRetrieveUpdate'],
      //   use: ['CL', 'PE']
      // }
      // {
      //   title: 'Logs',
      //   link: 'LogsList',
      //   associatedRoutes: [],
      //   use: ['CL', 'PE']
      // }
      // {
      //   title: 'Actividad de tiendas',
      //   link: 'BranchesActivityList',
      //   use: ['ax']
      // }
    ]
  },
  {
    title: "Reportes",
    icon: "report",
    link: "SentDocumentList",
    show: true,
    use: ["CL"],
    options: [
      // {
      //   title: 'Envío de documentos',
      //   link: 'SentDocumentList',
      //   associatedRoutes: [],
      //   use: ['CL', 'PE']
      // },
      {
        title: "Registro de ventas",
        link: "DocumentSummary",
        associatedRoutes: [],
        use: ["CL"],
        showSupport: true
      }
      // {
      //   title: 'Mis Dashboards',
      //   link: 'Dashboards'
      // },
      // {
      //   title: 'Log de distribución',
      //   use: ['CL'],
      //   showSupport: true
      // },
      // {
      //   title: 'Transacciones SII',
      //   link: 'IrsBuiltInReportTransactional',
      //   use: ['CL'],
      //   showSupport: true
      // },
      // {
      //   title: 'Resumen de ventas diarias',
      //   link: 'IrsBuiltInRvds',
      //   use: ['CL'],
      //   showSupport: true
      // },
      // {
      //   title: 'Explorador de dashboards',
      //   link: 'DefaultDashboardList'
      // },
      // {
      //   title: 'Favoritos',
      //   link: 'Favorites'
      // }
    ]
  },
  {
    title: "Centro de ayuda",
    description: "",
    link: "",
    use: ["CL", "PE"],
    options: []
  }
]

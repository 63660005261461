const complianceRoutes = [
  {
    path: '/:prefix(test)?/compliance',
    name: 'MainCompliance',
    meta: {
      displayNavigationDrawer: false,
      displayOrganization: true,
      parent: 'MainCompliance',
      requireAuth: true,
      use: ['ax'],
      requireAccount: true,
      title: 'Compras'
    },
    component: () => import('@/modules/compliance/views/MainCompliance')
  },
  {
    path: '/:prefix(test)?/compliance/welcome',
    name: 'WelcomeCompliance',
    meta: {
      displayNavigationDrawer: false,
      displayOrganization: true,
      parent: 'MainCompliance',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Información de documento'
    },
    component: () => import('@/modules/compliance/views/WelcomeCompliance')
  }
]
const list = () => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return complianceRoutes?.filter(({meta}) => meta?.use?.some((v) => v === env))
}
export default list()
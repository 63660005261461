export default [
  {
    path: '/:prefix(test)?/reconciliations',
    name: 'ReconciliationsListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax', 'fc'],
      // permissionsRequired: ['view_reconciliations', 'add_reconciliations'],
      title: 'Cuadratura'
    },
    component: () => import('@/modules/reconciliations/views/ReconciliationsListCreate')
  },
  {
    path: '/:prefix(test)?/reconciliations/documents/:id',
    name: 'ReconciliationsRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax', 'fc'],
      // permissionsRequired: ['view_reconciliations'],
      title: 'Detalles de cuadratura',
      breadcrumbs: [
        {
          text: 'Emisión',
          to: { name: 'ReconciliationsListCreate' },
          link: true,
          exact: true,
          disabled: false
        },
        {
          text: 'Cuadratura',
          disabled: true
        }
      ]
    },
    component: () => import('@/modules/reconciliations/views/ReconciliationsRetrieve')
  }
]

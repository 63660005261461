import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  reconciliationsDetail: {},
  reconciliationsCount: null,
  reconciliationsList: []
}

const mutations = {
  SET_TOOGLE_SENDTYPE: (state, type) => {
    state.sendTypeToggleDropdown = type
  },
  ...mutationsAbstract
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations
}
